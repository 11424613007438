.intro {
	background: url("../public/asset/Background.png") no-repeat;
	background-size: cover;
}

.bg-con-about {
	background: url("../public/asset/8.png") no-repeat;
	background-size: contain;
	background-position: center center;
}

.perfume-intro,
.perfume-detail {
	background: url("../public/asset/9.png") no-repeat;
	background-size: cover;
	background-position: center center;
}

.pp select {
	background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
		no-repeat;
	background-position: calc(100% - 0.75rem) center !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
	padding-right: 2rem !important;
}

.active-order-menu {
	font-weight: bold;
	border-bottom: 2px solid #92278f;
	color: #92278f;
}

th {
	text-align: left;
}

th, td {
	padding: 15px 15px;
	min-width: 100px;
}